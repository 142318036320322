import React from "react"
import { Link, graphql } from "gatsby"

  import {
    MdArrowBack,

  } from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const consultancy = ({data: {image1,consultancy }}) => {


    let imgProd = consultancy.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Consultoría tecnológicas`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                            <MdArrowBack
                                color="#fff"
                                size="2.3em"
                            /> 
                        </Link>
                        
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Consultoría tecnológicas </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            
                                <Link  className="" to="/#services-section">Servicios</Link> > </span> <span>Consultoría tecnológicas</span>
                            </p>
                    </div>
                    </div>
                </div>
            </section>
            <section className="text-justify">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Consultoría tecnológicas</h3>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Consultoría IT asesora a empresas y organizaciones para ayudarlas a comprender de qué manera la Tecnología de la Información y de la Comunicación se puede integrar con su negocio, a fin de crear valor sustentable a lo largo del tiempo. En Metalinks C.A. abordamos tu ámbito tecnológico y te asesoramos cual es la mejor decisión a tomar en cuanto a la infraestructura tecnológica y sistemática: </p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;No cabe duda, en muchos ámbitos de la vida tanto las personas como las empresas necesitamos de expertos que nos orienten y aconsejen, particularmente, en temas que no dominamos, con el fin de contar con elementos que nos permitan tomar la mejor decisión: ésta es la función de un asesor. Desde luego, hay unos extraordinarios, muy profesionales y con un amplio dominio del tema que les ocupa. Su apoyo es realmente invaluable.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>

                        <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >

                            


                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default consultancy

export const pageQuery = graphql`
  query FacByConsultancy{

    image1: file(relativePath: { eq: "consultancy_banner.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    } 
   
    consultancy: file(relativePath: { eq: "consultancy.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
    }

  }
`